import { BybitCoin, BybitInstrumentInfo } from './dto'

export interface BybitState {
  instrumentInfos: Record<`${BybitCoin}USDT`, BybitInstrumentInfo> | null;
  position: any[] | null;
}

export const state = (): BybitState => {
  return {
    instrumentInfos: null,
    position: null,
  }
}