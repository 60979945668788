import Vue from 'vue'
import VueRouter, { NavigationGuardNext, Route, RouteConfig } from 'vue-router'
import HomeView from '../views/HomeView.vue'
import LoginView from '@/views/LoginView.vue'
import axios from 'axios'

Vue.use(VueRouter)

const checkAuth = async (to: Route, from: Route, next: NavigationGuardNext) => {
  const access_token = await localStorage.getItem('access_token')
  if (!access_token) return router.push('/login');
  try {
    await axios.get(`${process.env.VUE_APP_API_BASE_URL}/auth/check`, {
      headers: {
        'Authorization': `Bearer ${access_token}`
      }
    })
    return next();
  } catch (error) {
    return router.push('/login');
  }
}

const routes: Array<RouteConfig> = [
  {
    path: '/login',
    name: 'login',
    component: LoginView,
  },
  {
    path: '/',
    name: 'home',
    component: HomeView,
    redirect: '/dashboard'
  },
  {
    path: '/dashboard',
    name: 'dashboard',
    component: () => import('../views/DashboardView.vue'),
    beforeEnter: checkAuth
  },
  {
    path: '/settings',
    name: 'settings',
    component: () => import('../views/SettingsView.vue'),
    beforeEnter: checkAuth
  },
  {
    path: '/user-settings',
    name: 'user-settings',
    component: () => import('../views/UserSettingsView.vue'),
    beforeEnter: checkAuth
  },
  {
    path: '/about',
    name: 'about',
    component: () => import('../views/AboutView.vue')
  }
]

const router = new VueRouter({
  mode: 'history',
  routes
})

export default router
