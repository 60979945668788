import { MutationTree } from 'vuex';
import { BybitState } from './state';
import { BybitCoin, BybitInstrumentInfo } from './dto';

export const mutations: MutationTree<BybitState> = {
  updateInstrumentsInfos(
    state: BybitState,
    instrumentsInfos: Record<`${BybitCoin}USDT`, BybitInstrumentInfo>
  ) {
    state.instrumentInfos = instrumentsInfos;
  },
  updatePosition(state: BybitState, position: any) {
    state.position = position;
  }
}