
import { Component, Vue } from 'vue-property-decorator';
import axios from 'axios';

@Component
export default class LoginView extends Vue {
  valid = false;

  password = '';

  email = '';
  
  snackbar = false;

  snackbarText = '';

  async submit() {
    try {
      const { data } = await axios
        .post(`${process.env.VUE_APP_API_BASE_URL}/auth/login`, {
          email: this.email,
          password: this.password,
        })
      localStorage.setItem('access_token', data.access_token)
      this.$router.push('/dashboard');
    } catch (error: any) {
      this.snackbar = true;
      this.snackbarText = error.response.data.message;
    }
  }
}
