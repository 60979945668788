import { ActionTree } from 'vuex';
import { BybitState } from './state';
import { BybitCoin, BybitInstrumentInfo } from './dto';

export const actions: ActionTree<BybitState, any> = {
  updateInstrumentsInfos(
    { commit, state },
    instrumentsInfos: Record<`${BybitCoin}USDT`, BybitInstrumentInfo>
  ) {
    commit('updateInstrumentsInfos', instrumentsInfos);

    if (state.position) {
      const positions = [];
      for (const p of state.position) {
        positions.push({
          ...p,
          unrealised_pnl:
            (parseFloat(instrumentsInfos[(p.symbol as `${BybitCoin}USDT`)].mark_price) * p.size
            - p.entry_price * p.size).toFixed(4)
        });
      }
      commit('updatePosition', positions);
    }
  },
  updatePosition({ commit }, position: any) {
    commit('updatePosition', position);
  }
}