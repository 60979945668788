import { Module } from 'vuex';
import { BybitState, state } from './state';
import { getters } from './getters';
import { actions } from './actions';
import { mutations } from './mutations';

const bybit: Module<BybitState, any> = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}

export default bybit;